import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import HttpService from "../services/http";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import { useSearchParams } from "react-router-dom";
import useAppLocalStorage from "../hooks/useAppLocalStorage";
import { Label, Radio, Spinner } from "flowbite-react";

// const planNameMapper = (plan: string | null) => {
//   switch (plan) {
//     case "UMD_PREMIUM_BUSINESS_ANNUAL_WITH_TRIAL":
//       return "Annual business";
//     case "UMD_PREMIUM_BUSINESS_MONTH":
//       return "Monthly business";
//     case "UMD_FREE_ANNUAL":
//       return "Annual free";
//     case "UMD_FREE_MONTH":
//       return "One month free";
//     case "UMD_ENTREPRISE_BUSINESS_ANNUAL_WITH_TRIAL":
//       return "Annual enterprise";
//     case "UMD_ENTREPRISE_BUSINESS_MONTH":
//       return "Monthly enterprise";
//     case "UMD_LARGE_BUSINESS_ANNUAL_WITH_TRIAL":
//       return "Annual large scale";
//     case "UMD_Large_BUSINESS_MONTH":
//       return "Monthly large scale";
//     default:
//       return plan;
//   }
// };

export enum EPlans {
  UMD_FREE = "UMD_FREE",
  UMD_PREMIUM_BUSINESS = "UMD_PREMIUM_BUSINESS",
  SUMOM_8PS8HPOLTF = "SUMOM_8PS8HPOLTF",
  SUMOM_MHMYDHE9NH = "SUMOM_MHMYDHE9NH",
  SUMOA_T9IZI415ZG = "SUMOA_T9IZI415ZG",
  SUMOA_9X8ZNA5QAO = "SUMOA_9X8ZNA5QAO",
  SUMOA_HOYZZNJVYT = "SUMOA_HOYZZNJVYT",
  SUMOA_EUEIVLUAUW = "SUMOA_EUEIVLUAUW",
  UMD_ENTREPRISE_BUSINESS = "UMD_ENTREPRISE_BUSINESS",
  SUMOM_GYASCZ0RSW = "SUMOM_GYASCZ0RSW",
  SUMOM_JVXKQNIFEN = "SUMOM_JVXKQNIFEN",
  SUMOA_YNL4PD6BOM = "SUMOA_YNL4PD6BOM",
  SUMOA_GSW9Q445J1 = "SUMOA_GSW9Q445J1",
  SUMOA_BOAXCZVLIQ = "SUMOA_BOAXCZVLIQ",
  SUMOA_VJTRNHTBIB = "SUMOA_VJTRNHTBIB",
  UMD_LARGE_BUSINESS = "UMD_LARGE_BUSINESS",
  SUMOM_A8NQTU2UIB = "SUMOM_A8NQTU2UIB",
  SUMOM_7YV3KX83JA = "SUMOM_7YV3KX83JA",
  SUMOA_7AWE1A948A = "SUMOA_7AWE1A948A",
  SUMOA_ZN8D246RA5 = "SUMOA_ZN8D246RA5",
  SUMOA_ZEDIVSBYFC = "SUMOA_ZEDIVSBYFC",
  SUMOA_HZVGJGKYLM = "SUMOA_HZVGJGKYLM",
}

export const premiumPlans = [
  "UMD_PREMIUM_BUSINESS_MONTH",
  "SUMOM_8PS8HPOLTF",
  "SUMOM_MHMYDHE9NH",
  "UMD_PREMIUM_BUSINESS_ANNUAL_WITH_TRIAL",
  "UMD_PREMIUM_BUSINESS_ANNUAL",
  "SUMOA_T9IZI415ZG",
  "SUMOA_EUEIVLUAUW",
  "SUMOA_9X8ZNA5QAO",
  "SUMOA_HOYZZNJVYT",
];

export type PlanNames =
  // BASIC
  | "UMD_FREE_MONTH"
  | "UMD_FREE_ANNUAL"
  // PREMIUM
  | "UMD_PREMIUM_BUSINESS_MONTH"
  | "SUMOM_8PS8HPOLTF"
  | "SUMOM_MHMYDHE9NH"
  | "UMD_PREMIUM_BUSINESS_ANNUAL_WITH_TRIAL"
  | "UMD_PREMIUM_BUSINESS_ANNUAL"
  | "SUMOA_T9IZI415ZG"
  | "SUMOA_EUEIVLUAUW"
  | "SUMOA_9X8ZNA5QAO"
  | "SUMOA_HOYZZNJVYT"
  // ENTERPRISE
  | "UMD_ENTREPRISE_BUSINESS_MONTH"
  | "SUMOM_GYASCZ0RSW"
  | "SUMOM_JVXKQNIFEN"
  | "UMD_ENTREPRISE_BUSINESS_ANNUAL_WITH_TRIAL"
  | "UMD_ENTREPRISE_BUSINESS_ANNUAL"
  | "SUMOA_YNL4PD6BOM"
  | "SUMOA_VJTRNHTBIB"
  | "SUMOA_GSW9Q445J1"
  | "SUMOA_BOAXCZVLIQ"
  // LARGE_BUSINESSES
  | "UMD_LARGE_BUSINESS_MONTH"
  | "SUMOM_A8NQTU2UIB"
  | "SUMOM_7YV3KX83JA"
  | "UMD_LARGE_BUSINESS_ANNUAL_WITH_TRIAL"
  | "UMD_LARGE_BUSINESS_ANNUAL"
  | "SUMOA_7AWE1A948A"
  | "SUMOA_HZVGJGKYLM"
  | "SUMOA_ZN8D246RA5"
  | "SUMOA_ZEDIVSBYFC";

type CurrencyType = "USD" | "EUR" | "CHF";

export const decideGeneralName = (planName: PlanNames) => {
  switch (planName) {
    case "UMD_FREE_MONTH":
    case "UMD_FREE_ANNUAL":
      return "BASIC";
    case "UMD_PREMIUM_BUSINESS_MONTH":
    case "SUMOM_8PS8HPOLTF":
    case "SUMOM_MHMYDHE9NH":
    case "UMD_PREMIUM_BUSINESS_ANNUAL_WITH_TRIAL":
    case "UMD_PREMIUM_BUSINESS_ANNUAL":
    case "SUMOA_T9IZI415ZG":
    case "SUMOA_EUEIVLUAUW":
    case "SUMOA_9X8ZNA5QAO":
    case "SUMOA_HOYZZNJVYT":
      return "PREMIUM";
    case "UMD_ENTREPRISE_BUSINESS_MONTH":
    case "SUMOM_GYASCZ0RSW":
    case "SUMOM_JVXKQNIFEN":
    case "UMD_ENTREPRISE_BUSINESS_ANNUAL_WITH_TRIAL":
    case "UMD_ENTREPRISE_BUSINESS_ANNUAL":
    case "SUMOA_YNL4PD6BOM":
    case "SUMOA_VJTRNHTBIB":
    case "SUMOA_GSW9Q445J1":
    case "SUMOA_BOAXCZVLIQ":
      return "ENTERPRISE";
    case "UMD_LARGE_BUSINESS_MONTH":
    case "SUMOM_A8NQTU2UIB":
    case "SUMOM_7YV3KX83JA":
    case "UMD_LARGE_BUSINESS_ANNUAL_WITH_TRIAL":
    case "UMD_LARGE_BUSINESS_ANNUAL":
    case "SUMOA_7AWE1A948A":
    case "SUMOA_HZVGJGKYLM":
    case "SUMOA_ZN8D246RA5":
    case "SUMOA_ZEDIVSBYFC":
      return "LARGE_BUSINESSES";
    default:
      return "UNKNOWN";
  }
};

const Plans = ({
  user_identification,
  entity_identification,
  isInBilling = false,
  chosenPlan = null,
  hasPremiumPlan,
  user_email,
  onCloseModal,
}: {
  user_identification: string;
  entity_identification: string;
  isInBilling?: boolean;
  chosenPlan?: string | null;
  hasPremiumPlan?: boolean;
  user_email?: string;
  onCloseModal?: VoidFunction;
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const localStorage = useAppLocalStorage();
  const [searchParams, SetUrlSearchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const [isYearly, setIsYearly] = useState<boolean>(true);
  const [selectedCurrency, setSelectedCurrency] = useState<
    "USD" | "EUR" | "CHF"
  >("USD");

  const currencySymbolMapper = {
    USD: "$",
    EUR: "€",
    CHF: "CHF",
  };

  const plans: Array<{
    label: {
      monthly: {
        USD: string;
        EUR: string;
        CHF: string;
      };
      annually: {
        USD: string;
        USD_UPGRADE: string;
        EUR: string;
        EUR_UPGRADE: string;
        CHF: string;
        CHF_UPGRADE: string;
      };
    };
    title: string;
    description: string;
    price: {
      monthly: string;
      annually: string;
      discount: string;
    };
    actionBtnText: string;
    features: string[];
    generalName: string;
  }> = [
    {
      label: {
        monthly: {
          USD: "UMD_FREE_MONTH",
          EUR: "UMD_FREE_MONTH",
          CHF: "UMD_FREE_MONTH",
        },
        annually: {
          USD: "UMD_FREE_ANNUAL",
          USD_UPGRADE: "UMD_FREE_ANNUAL",
          EUR: "UMD_FREE_ANNUAL",
          EUR_UPGRADE: "UMD_FREE_ANNUAL",
          CHF: "UMD_FREE_ANNUAL",
          CHF_UPGRADE: "UMD_FREE_ANNUAL",
        },
      },
      title: t("Pricing.Section1.Basic.key1"),
      description: t("Pricing.Section1.Basic.key2"),
      price: {
        monthly: t("Pricing.Section1.Basic.key3"),
        annually: t("Pricing.Section1.Basic.key3"),
        discount: t("Pricing.Section1.Basic.key16"),
      },
      actionBtnText: t("Pricing.Section1.Basic.key4"),
      features: [
        t("Pricing.Section1.Basic.key5"),
        t("Pricing.Section1.Basic.key6"),
        t("Pricing.Section1.Basic.key7"),
        t("Pricing.Section1.Basic.key8"),
        t("Pricing.Section1.Basic.key9"),
        t("Pricing.Section1.Basic.key10"),
        t("Pricing.Section1.Basic.key11"),
        t("Pricing.Section1.Basic.key12"),
        t("Pricing.Section1.Basic.key13"),
        t("Pricing.Section1.Basic.key14"),
        t("Pricing.Section1.Basic.key15"),
        t("Pricing.Section1.Basic.key17"),
        t("Pricing.Section1.Basic.key18"),
      ],
      generalName: "Free",
    },
    {
      label: {
        monthly: {
          USD: "UMD_PREMIUM_BUSINESS_MONTH",
          EUR: "SUMOM_8PS8HPOLTF",
          CHF: "SUMOM_MHMYDHE9NH",
        },
        annually: {
          USD: "UMD_PREMIUM_BUSINESS_ANNUAL_WITH_TRIAL",
          USD_UPGRADE: "UMD_PREMIUM_BUSINESS_ANNUAL",
          EUR: "SUMOA_T9IZI415ZG",
          EUR_UPGRADE: "SUMOA_EUEIVLUAUW",
          CHF: "SUMOA_9X8ZNA5QAO",
          CHF_UPGRADE: "SUMOA_HOYZZNJVYT",
        },
      },
      title: t("Pricing.Section1.Premium.key1"),
      description: t("Pricing.Section1.Premium.key2"),
      price: {
        monthly: "49",
        annually: "29",
        discount: `49`,
      },
      actionBtnText: t("Pricing.Section1.Premium.key3"),
      features: [
        t("Pricing.Section1.Premium.key4"),
        t("Pricing.Section1.Premium.key5"),
        t("Pricing.Section1.Premium.key6"),
        t("Pricing.Section1.Premium.key7"),
        t("Pricing.Section1.Premium.key8"),
        t("Pricing.Section1.Premium.key9"),
        t("Pricing.Section1.Premium.key10"),
        t("Pricing.Section1.Premium.key11"),
        t("Pricing.Section1.Premium.key12"),
        t("Pricing.Section1.Premium.key13"),
        t("Pricing.Section1.Premium.key14"),
        t("Pricing.Section1.Premium.key15"),
        t("Pricing.Section1.Premium.key16"),
        // isYearly ? t("Pricing.Section1.Premium.key17") : "",
        // t("Pricing.Section1.Premium.key18"),
        t("Pricing.Section1.Premium.key19"),
        t("Pricing.Section1.Premium.key20"),
        t("Pricing.Section1.Premium.key21"),
      ],
      generalName: "Premium",
    },
    {
      label: {
        monthly: {
          USD: "UMD_ENTREPRISE_BUSINESS_MONTH",
          EUR: "SUMOM_GYASCZ0RSW",
          CHF: "SUMOM_JVXKQNIFEN",
        },
        annually: {
          USD: "UMD_ENTREPRISE_BUSINESS_ANNUAL_WITH_TRIAL",
          USD_UPGRADE: "UMD_ENTREPRISE_BUSINESS_ANNUAL",
          EUR: "SUMOA_YNL4PD6BOM",
          EUR_UPGRADE: "SUMOA_VJTRNHTBIB",
          CHF: "SUMOA_GSW9Q445J1",
          CHF_UPGRADE: "SUMOA_BOAXCZVLIQ",
        },
      },
      title: t("Pricing.Section1.Enterprise.key1"),
      description: t("Pricing.Section1.Enterprise.key2"),
      price: {
        monthly: "162",
        annually: "129",
        discount: `162`,
      },
      actionBtnText: t("Pricing.Section1.Enterprise.key3"),
      features: [
        t("Pricing.Section1.Enterprise.key4"),
        t("Pricing.Section1.Enterprise.key5"),
        t("Pricing.Section1.Enterprise.key6"),
        t("Pricing.Section1.Enterprise.key7"),
        t("Pricing.Section1.Enterprise.key8"),
        t("Pricing.Section1.Enterprise.key9"),
        t("Pricing.Section1.Enterprise.key10"),
        t("Pricing.Section1.Enterprise.key11"),
        t("Pricing.Section1.Enterprise.key12"),
        t("Pricing.Section1.Enterprise.key13"),
        t("Pricing.Section1.Enterprise.key14"),
        t("Pricing.Section1.Enterprise.key15"),
        t("Pricing.Section1.Enterprise.key16"),
        // isYearly ? t("Pricing.Section1.Enterprise.key17") : "",
        t("Pricing.Section1.Enterprise.key18"),
        t("Pricing.Section1.Enterprise.key19"),
        t("Pricing.Section1.Enterprise.key20"),
        t("Pricing.Section1.Enterprise.key21"),
      ],
      generalName: "Enterprise",
    },
    {
      label: {
        monthly: {
          USD: "UMD_LARGE_BUSINESS_MONTH",
          EUR: "SUMOM_A8NQTU2UIB",
          CHF: "SUMOM_7YV3KX83JA",
        },
        annually: {
          USD: "UMD_LARGE_BUSINESS_ANNUAL_WITH_TRIAL",
          USD_UPGRADE: "UMD_LARGE_BUSINESS_ANNUAL",
          EUR: "SUMOA_7AWE1A948A",
          EUR_UPGRADE: "SUMOA_HZVGJGKYLM",
          CHF: "SUMOA_ZN8D246RA5",
          CHF_UPGRADE: "SUMOA_ZEDIVSBYFC",
        },
      },
      title: t("Pricing.Section1.Large businesses.key1"),
      description: t("Pricing.Section1.Large businesses.key2"),
      price: {
        monthly: "350",
        annually: "280",
        discount: `350`,
      },
      actionBtnText: t("Pricing.Section1.Large businesses.key3"),
      features: [
        t("Pricing.Section1.Large businesses.key4", {
          currency: currencySymbolMapper[selectedCurrency],
        }),
        t("Pricing.Section1.Large businesses.key5"),
        t("Pricing.Section1.Large businesses.key6"),
        t("Pricing.Section1.Large businesses.key7"),
        t("Pricing.Section1.Large businesses.key8"),
        t("Pricing.Section1.Large businesses.key9"),
        t("Pricing.Section1.Large businesses.key10"),
        t("Pricing.Section1.Large businesses.key11"),
        t("Pricing.Section1.Large businesses.key12"),
        t("Pricing.Section1.Large businesses.key13"),
        t("Pricing.Section1.Large businesses.key14"),
        t("Pricing.Section1.Large businesses.key15"),
        t("Pricing.Section1.Large businesses.key16"),
        // isYearly ? t("Pricing.Section1.Large businesses.key17") : "",
        t("Pricing.Section1.Large businesses.key18"),
        t("Pricing.Section1.Large businesses.key19"),
        t("Pricing.Section1.Large businesses.key20"),
        t("Pricing.Section1.Large businesses.key21"),
      ],
      generalName: "Large businesses",
    },
  ];

  const freeSubscriptionMutation = useMutation<any, AxiosError<any, any>, any>(
    (data: { email: string }) =>
      HttpService.post(
        `/${user_identification}/${entity_identification}/billing/create-free-subscription`,
        data
      ),
    {
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const LoginUserMutation = useMutation<any, AxiosError<any, any>>(
    (credentials: any) =>
      HttpService.post("/auth/login", {
        user_email: credentials.user_email,
        user_password: credentials.user_password,
      }),
    {
      onError: (err) => {
        localStorage.removeItem("_umd_credential");

        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const profileMutation = useMutation<any, AxiosError<any, any>>(() =>
    HttpService.get(
      `/${auth.customer_identification}/${auth.user.entityIdentification}/user/${auth.customer_identification}/profile`,
      {
        auth: HttpService.getToken(),
      }
    )
  );

  const socialLoginMutation = useMutation<
    any,
    any,
    {
      providerPayload: { access_token: string; provider: string };
      type: string;
      user_first_name?: string;
      user_last_name?: string;
      user_country_code?: string;
      entity_name?: string;
      entity_description?: string;
      user_birth_date?: string;
      user_user_name?: string;
    }
  >((data) => HttpService.post("/auth/social/login/owner", data));

  const handleLogPlanAndRedirect = (destination: string, plan: string) => {
    if (plan === "UMD_FREE_MONTH" || plan === "UMD_FREE_ANNUAL") {
      if (auth.userEndedSubscription && auth.user.isAuthenticated) {
        profileMutation.mutate(undefined, {
          onSuccess: (res) => {
            if (res?.data?.element?.user_email) {
              const targetEmail = res.data.element.user_email;

              freeSubscriptionMutation.mutate(
                { email: targetEmail },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries("verify-plans");

                    onCloseModal?.();
                  },
                  onError(error, variables, context) {
                    toast.error(error.response?.data.message || error.message);
                  },
                }
              );
            }
          },
        });
      } else {
        let credentials = localStorage.getItem("_umd_credential");

        if (credentials) {
          let parsedCredential = JSON.parse(credentials);

          freeSubscriptionMutation.mutate(
            { email: parsedCredential?.user_email },
            {
              onSuccess: () => {
                LoginUserMutation.mutate(parsedCredential, {
                  onSuccess: (res) => {
                    auth.login({
                      ...res.data.element,
                      rememberMe: parsedCredential.rememberMe,
                    });

                    localStorage.removeItem("_umd_credential");

                    window.location.href = `/control-panel?plan=BASIC&plan_name=${plan}&status=true`;
                  },
                  onError(error, variables, context) {
                    toast.error(error.response?.data.message || error.message);
                  },
                });
              },
            }
          );
        } else if (user_email) {
          freeSubscriptionMutation.mutate(
            { email: user_email },
            {
              onSuccess: () => {
                let credentials = localStorage.getItem("_umd_oauth_credential");

                if (credentials) {
                  let parsedCredential = JSON.parse(credentials);

                  socialLoginMutation.mutate(
                    {
                      providerPayload: parsedCredential,
                      type: "login",
                    },
                    {
                      onSuccess({ data }, variables, context) {
                        localStorage.removeItem("_umd_oauth_credential");

                        auth.login({
                          ...data.element,
                          rememberMe: true,
                        });

                        window.location.href = `/control-panel?plan=BASIC&plan_name=${plan}&status=true`;
                      },
                    }
                  );
                }
              },
              onError(error, variables, context) {
                toast.error(error.response?.data.message || error.message);
              },
            }
          );
        }
      }
    } else {
      if (typeof window !== "undefined") {
        localStorage.setItem("_umd_service_client_plan", plan);

        window.location.href = destination;
      }
    }
  };

  const localeBaseCurrencyMapper = useCallback((postfix: string) => {
    const mapper: { [key: string]: CurrencyType } = {
      EU: "EUR",
      US: "USD",
      FR: "EUR",
      CH: "CHF",
    };

    return mapper[postfix];
  }, []);

  useEffect(() => {
    if (
      searchParams.has("language") &&
      !searchParams.has("currency") &&
      ["US", "EU", "FR", "CH"].includes(
        searchParams.get("language")?.split("-").pop() as string
      )
    )
      setSelectedCurrency(
        localeBaseCurrencyMapper(
          searchParams.get("language")?.split("-").pop() as string
        )
      );

    if (
      searchParams.has("currency") &&
      ["USD", "EUR", "CHF"].includes(searchParams.get("currency") as string)
    )
      setSelectedCurrency(searchParams.get("currency") as CurrencyType);

    setIsYearly(searchParams.has("plan_type", "monthly") ? false : true);
  }, [localeBaseCurrencyMapper, searchParams]);

  return (
    <div className="plans-page">
      <div className="items-center grid grid-cols-12 gap-6 mb-6">
        <div className="lg:col-span-4 sm:col-span-4 col-span-12 sm:text-left text-center">
          <h2 className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-none mb-1">
            {t("Pricing.Section1.key1")}
          </h2>
          <p className="text-gray-500 text-base font-normal font-['Figtree'] leading-normal">
            {t("Pricing.Section1.key2")}
          </p>
          <p className="text-sky-600 text-sm font-normal font-['Figtree'] leading-tight mt-auto">
            {t("Pricing.Section1.key3")}
          </p>
        </div>

        <div className="lg:col-span-4 sm:col-span-4 col-span-12 flex justify-center">
          <div className="w-80 h-[42px] p-0.5 bg-gray-100 rounded-lg justify-center items-center gap-0.5 inline-flex">
            <button
              className={`grow shrink basis-0 h-[38px] px-[25px] py-[9px] ${
                !isYearly
                  ? "bg-white border border-gray-200 border-opacity-10 text-gray-700 shadow"
                  : "bg-gray-100 text-gray-500"
              } rounded-md justify-center items-center flex text-sm font-normal font-['Figtree'] leading-tight`}
              onClick={() => setIsYearly(false)}
            >
              {t("Pricing.Section1.key4")}
            </button>
            <button
              className={`grow shrink basis-0 h-[38px] px-[25px] py-[9px] ${
                isYearly
                  ? "bg-white border border-gray-200 border-opacity-10 text-gray-700 shadow"
                  : "bg-gray-100 text-gray-500"
              } rounded-md justify-center items-center flex text-sm font-normal font-['Figtree'] leading-tight`}
              onClick={() => setIsYearly(true)}
            >
              {t("Pricing.Section1.key5")}
            </button>
          </div>
        </div>

        <div className="lg:col-span-4 sm:col-span-4 col-span-12 flex flex-col sm:justify-start justify-center sm:items-center items-center h-full">
          <fieldset className="flex max-w-md flex-col gap-4">
            <legend className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal mb-4">
              {t("Pricing.Section1.key7")}
            </legend>

            <div className="flex items-center gap-4">
              {["USD", "EUR", "CHF"].map((currency) => (
                <div className="flex items-center gap-2" key={currency}>
                  <Radio
                    id={currency}
                    name="currency"
                    value={currency}
                    checked={selectedCurrency === currency}
                    onChange={(e) => {
                      setSelectedCurrency(e.target.value as CurrencyType);

                      if (searchParams.has("currency"))
                        SetUrlSearchParams({ currency: e.target.value });
                    }}
                  />
                  <Label
                    htmlFor={currency}
                    className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight"
                  >
                    {currency}
                  </Label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </div>

      <div className="planing grid grid-cols-12 gap-6">
        {[
          ...(auth?.user?.customer_top_plan
            ? plans.filter((p) => p.generalName !== "Free")
            : plans),
        ].map((plan, index) => (
          <div
            key={plan.title}
            className={`${
              auth?.user?.customer_top_plan ? "lg:col-span-4" : "lg:col-span-3"
            } lg:col-span-3 sm:col-span-6 col-span-12`}
          >
            <div
              className={`bg-gray-100 rounded-lg shadow border ${
                (searchParams.has("plan_name") &&
                  Object.values(plan.label.annually).some(
                    (value) => value === searchParams.get("plan_name")
                  )) ||
                (searchParams.has("plan_name") &&
                  Object.values(plan.label.monthly).some(
                    (value) => value === searchParams.get("plan_name")
                  ))
                  ? "border-sky-500"
                  : "border-gray-200 border-opacity-10"
              } flex-col justify-start items-start inline-flex w-full`}
            >
              <div
                className={`self-stretch px-[25px] pt-[25px] pb-6 flex-col justify-start items-start gap-8 flex`}
              >
                <div className="self-stretch h-[84px] flex-col justify-start items-start gap-4 flex">
                  <div className="self-stretch text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
                    {plan.title}
                  </div>
                  <div className="self-stretch text-gray-500 text-sm font-normal font-['Figtree'] leading-tight min-h-20">
                    {plan.description}
                  </div>
                </div>

                <div
                  className={`self-stretch justify-start gap-1 ${
                    isYearly
                      ? "items-start flex flex-col"
                      : "items-end inline-flex"
                  }`}
                >
                  <div className="text-gray-700 text-4xl font-bold font-['Figtree'] leading-10 flex items-end">
                    {plan.generalName !== "Free" &&
                      selectedCurrency !== "EUR" &&
                      currencySymbolMapper[selectedCurrency]}{" "}
                    {isYearly ? plan.price.annually : plan.price.monthly}
                    {plan.generalName !== "Free" &&
                      selectedCurrency === "EUR" &&
                      " " + currencySymbolMapper[selectedCurrency]}
                    {plan.generalName !== "Free" && (
                      <div className="text-gray-700 text-2xl font-bold font-['Figtree'] leading-10 ml-2">
                        {t("Pricing.Section1.key8")}
                      </div>
                    )}
                  </div>
                  {isYearly && (
                    <div
                      className={`text-gray-500 text-base font-normal font-['Figtree'] ${
                        plan.generalName !== "Free" ? "line-through" : ""
                      } leading-normal`}
                    >
                      {plan.generalName !== "Free" &&
                        selectedCurrency !== "EUR" &&
                        currencySymbolMapper[selectedCurrency]}{" "}
                      {plan.price.discount}{" "}
                      {plan.generalName !== "Free" &&
                        selectedCurrency === "EUR" &&
                        currencySymbolMapper[selectedCurrency]}{" "}
                      {plan.generalName !== "Free" &&
                        t("Pricing.Section1.key8")}
                    </div>
                  )}
                </div>

                <button
                  className="px-[17px] py-[9px] w-full bg-sky-500 rounded-md justify-center items-center inline-flex text-white text-sm font-normal font-['Figtree'] leading-tight"
                  onClick={() =>
                    handleLogPlanAndRedirect(
                      `${
                        process.env.REACT_APP_PAYMENT_SERVICE
                      }/payment/subscription?template_image_url=${
                        process.env.REACT_APP_PAYMENT_SERVICE_UMD_LOGO_URL
                      }&redirect_page=${process.env.REACT_APP_HOST_URL}/${
                        isInBilling ? "control-panel/billing" : "login"
                      }&user_identification=${user_identification}&payment_plan_identification=${
                        isInBilling
                          ? isYearly
                            ? plan.label.annually[`${selectedCurrency}_UPGRADE`]
                            : plan.label.monthly[selectedCurrency]
                          : isYearly
                          ? plan.label.annually[`${selectedCurrency}_UPGRADE`]
                          : plan.label.monthly[selectedCurrency]
                      }&language_code=en&entity_identification=${entity_identification}`,
                      isInBilling
                        ? isYearly
                          ? plan.label.annually[`${selectedCurrency}_UPGRADE`]
                          : plan.label.monthly[selectedCurrency]
                        : isYearly
                        ? plan.label.annually[`${selectedCurrency}_UPGRADE`]
                        : plan.label.monthly[selectedCurrency]
                    )
                  }
                  disabled={
                    freeSubscriptionMutation.isLoading ||
                    profileMutation.isLoading
                  }
                >
                  {freeSubscriptionMutation.isLoading ||
                  profileMutation.isLoading ? (
                    <Spinner size={"sm"} />
                  ) : null}{" "}
                  {plan.actionBtnText}
                </button>
              </div>
              <div className="self-stretch h-px bg-gray-200" />
              <div className="self-stretch px-[25px] pt-6 pb-[33px] flex-col justify-start items-start gap-6 flex">
                <div className="self-stretch text-gray-500 text-xs font-semibold font-['Figtree'] uppercase leading-none">
                  {t("Pricing.Section1.key6")}
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                  {plan.features.map((feature) => {
                    if (feature === "") return null;
                    return (
                      <div
                        key={feature}
                        className="self-stretch justify-start items-start gap-3 inline-flex"
                      >
                        <div className="w-5 h-5 relative">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z"
                              fill="#10B981"
                            />
                          </svg>
                        </div>
                        <div className="grow shrink basis-0 text-gray-500 text-sm font-normal font-['Figtree'] leading-tight">
                          {feature}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
